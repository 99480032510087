.uno-card {
    height: 200px;
    width: 150px;
    border-radius: 15px;
    border: 6px solid white;
    position: relative;
    cursor: pointer;
    box-shadow: -5px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 11;
    overflow: hidden;
    margin-left: -50px;
    transition: transform 1s ease;
  }

  .uno-card:first-child {
    margin-left: 0px;
  }

  /* .uno-card:hover {
    transform: rotate(-10deg);
  } */

  .uno-card-small-number {
    color: white;
    text-shadow: black 1px 3px 1px;
    font-weight: bold;
    /* font-size: 2em; */
    font-size: 1em;
    position: absolute;
    left: 5px;
    top: -5px;
    content: \002298;
  }

  .flipped {
    transform: rotate(180deg);
    /* right: 5px; */
    left: 42px !important;
    bottom: -5px;
  }

  .uno-card-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .uno-card-middle-circle {
    background-color: white;
    /* width: 100%; */
    width: 95%;
    height: 70%;
    border-radius: 100%;
    transform: skew(-15deg, -15deg);
  }

  .uno-card-middle-circle-number {
    transform: skew(0deg, 0deg);
    /* font-size: 4.5em; */
    font-size: 2.5em;
    font-weight: bold;
    position: absolute;
    z-index: 10;
    margin-bottom: 0;
    text-shadow: black 1px 3px 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .uno-card-middle-circle-word {
    transform: skew(0deg, 0deg);
    font-size: 1.5em;
    font-weight: bold;
    position: absolute;
    z-index: 10;
    margin-bottom: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .uno-reverse-card-icon {
    height: 10vh;
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .reverse-icon-small {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 10;
  }